import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS, BREAKPOINTS } from "@/scripts/constants";
import StepMobile from "./StepMobile";
import StepDesktop from "./StepDesktop";

export default class Steps extends Element {
	mounted() {
		this.steps = this.el.querySelectorAll(".c-steps__step");
		this.contents = [...this.steps].map((step) => step.querySelector(".c-steps__step-content"));
		this.biggestMedia = null;
		this.media = [...this.steps].map((step) => {
			const media = step.querySelector(".c-steps__step-media");

			// Set the transition to prevent the transition on init
			media.style.transition = "opacity 250ms ease-in"

			/**
			 * Set the biggest media to set the section to this height. This is
			 * needed due to the fact that media is position absolute
			 */
			if (!this.biggestMedia || (this.biggestMedia && this.biggestMedia.offsetHeight < media.offsetHeight)) {
				this.biggestMedia = media;
			}

			return media;
		});

		this.isMobile = null;

		// Keep a list of all active listeners to be able to destroy them
		this.activeClickListeners = Array.from({ length: this.steps.length }, (_, index) => () => this.onClickStep.bind(this, index));

		this.activeIndex = 0;
		this.cycleDuration = 8000;
		this.shouldCycle = true;
		this.interval = null;

		this.stepsMobile = [...this.steps].map((el, index) => new StepMobile(el, index, this.activeIndex));
		this.stepsDesktop = [...this.steps].map((el, index) => new StepDesktop(el, index, this.activeIndex, true));
	}

	events() {
		EventEmitter.on(EVENTS.SCREEN_SIZE_CHANGE, (breakpoint) => this.updateClickListeners(breakpoint));
	}

	setCycleInterval() {
		// Don't cycle if not allowed
		if (!this.shouldCycle) {
			return;
		}

		this.interval = setInterval(() => {
			this.activeIndex++;

			// Reset loop if the end of the steps is reached
			if (this.activeIndex >= this.steps.length) {
				this.activeIndex = 0;
			}

			this.setActiveStepDesktop(this.activeIndex, true);
		}, this.cycleDuration);
	}

	clearCycleInterval() {
		// Destroy the cycle interval
		clearInterval(this.interval);
	}

	updateClickListeners(breakpoint) {
		const isMobile = breakpoint < BREAKPOINTS.VP_9;

		// Recalculate all heights when user changes viewport width
		if (isMobile) {
			this.stepsMobile.forEach((accordion) => {
				accordion.initHeights();
			});
		} else {
			this.el.style.minHeight = `${this.biggestMedia.offsetHeight}px`;

			this.stepsDesktop.forEach((accordion) => {
				accordion.initHeights();
			});
		}

		/**
		 * Only re-init the listeners if the viewport changes from mobile or
		 * desktop
		 */
		if (this.isMobile === isMobile) {
			return;
		}

		if (isMobile) {
			this.clearCycleInterval();

			this.initMobileListeners();

			this.isMobile = isMobile;

			return;
		}

		this.setCycleInterval();

		this.initDesktopListeners();

		this.isMobile = isMobile;
	}

	initMobileListeners() {
		this.stepsDesktop.forEach((accordion) => {
			accordion.setActiveIndex(this.activeIndex);

			accordion.destroy();
		});

		this.stepsMobile.forEach((accordion) => {
			// Set the activeIndex so the step knows where to pick up
			accordion.setActiveIndex(this.activeIndex);
		});

		this.contents.forEach((content, index) => {
			// Remove all old listeners to prevent memory leaks
			content.removeEventListener("click", this.activeClickListeners[index]);
		});

		this.steps.forEach((step, index) => {
			/**
			 * Make sure the exact function is destroyable by binding it with
			 * "this"
			 */
			this.activeClickListeners[index] = this.onClickStepMobile.bind(this, index);

			step.addEventListener("click", this.activeClickListeners[index]);
		});
	}

	initDesktopListeners() {
		this.stepsMobile.forEach((accordion) => {
			accordion.setActiveIndex(this.activeIndex);

			accordion.destroy();
		});

		this.stepsDesktop.forEach((accordion) => {
			// Set the activeIndex so the step knows where to pick up
			accordion.setActiveIndex(this.activeIndex);
		});

		this.steps.forEach((step, index) => {
			// Remove all old listeners to prevent memory leaks
			step.removeEventListener("click", this.activeClickListeners[index]);
		});

		this.contents.forEach((content, index) => {
			/**
			 * Make sure the exact function is destroyable by binding it with
			 * "this"
			 */
			this.activeClickListeners[index] = this.onClickStepDesktop.bind(this, index);

			content.addEventListener("click", this.activeClickListeners[index]);
		});
	}

	onClickStepMobile(clickIndex) {
		this.activeIndex = clickIndex;

		this.stepsMobile.forEach((step, index) => {
			const isActive = step.isActive;

			step.setActiveIndex(this.activeIndex);

			// Only open the clicked step
			if (index === clickIndex && !isActive) {
				step.open();

				return;
			}

			// Only close opened steps
			if (!isActive) {
				return;
			}

			step.close();
		});
	}

	onClickStepDesktop(clickIndex) {
		this.clearCycleInterval();

		// Disable cycling as soon as a user has clicked on a step
		this.shouldCycle = false;

		this.setActiveStepDesktop(clickIndex, false);
	}

	setActiveStepDesktop(clickIndex, showCycle) {
		this.activeIndex = clickIndex;

		this.stepsDesktop.forEach((step, index) => {
			const isActive = step.isActive;

			step.setShowCycle(showCycle);
			step.setActiveIndex(this.activeIndex);

			// Only open the clicked step
			if (index === clickIndex && !isActive) {
				step.open();

				return;
			}

			// Only close opened steps
			if ((index === clickIndex && isActive) || !isActive) {
				return;
			}

			step.close();
		});
	}
}
