export default class StepAccordion {
	constructor(el, index, activeIndex) {
		this.el = el;
		this.index = index;
		this.activeIndex = activeIndex;
		this.isActive = false;
		this.header = el.querySelector(".c-steps__step-header");

		this.closedHeight = 0;
		this.openedHeight = 0;
	}

	initHeights() {
		this.isActive = true;

		this.el.classList.add("is-active");
		this.el.style.height = "auto";


		this.closedHeight = `${this.header.offsetHeight}px`;
		this.openedHeight = `${this.el.offsetHeight}px`;

		if (this.index === this.activeIndex) {
			return;
		}

		this.el.classList.remove("is-active");
		this.el.style.height = this.closedHeight;

		this.isActive = false;
	}

	setActiveIndex(index) {
		this.activeIndex = index;
	}

	open() {
		this.isActive = true;
		this.el.classList.add("is-active");

		this.animate(this.closedHeight, this.openedHeight, () => null);
	}

	close() {
		this.animate(this.openedHeight, this.closedHeight, () => {
			this.el.classList.remove("is-active");
			this.isActive = false;
		});
	}

	animate(start, end, onFinishCallback) {
		this.el.style.height = "";

		this.closedHeight = `${this.header.offsetHeight}px`;
		this.openedHeight = `${this.el.offsetHeight}px`;

		this.animation = this.el.animate({
			height: [start, end]
		}, {
			duration: 250,
			easing: "ease-out"
		});

		this.animation.onfinish = () => {
			this.el.style.height = "";

			onFinishCallback();
		};
	}

	destroy() {
		this.el.style.height = "";
	}
}
