import { Element } from "@/scripts/extends";

export default class Hamburger extends Element {
	mounted() {
		this.openClass = "is-cross";
		this.isOpen = this.el.classList.contains(this.openClass);

		this.labelEl = this.el.querySelector(".c-hamburger__label");
		this.label = this.el.dataset.label;
		this.openLabel = this.el.dataset.labelOpen;
		this.closeLabel = this.el.dataset.labelClose;
	}

	events() {
		this.el.addEventListener("click", (e) => this.handleClick(e));
	}

	handleClick(e) {
		if (e) {
			this.toggle();
		}
	}

	toggle() {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	close() {
		this.isOpen = false;

		this.el.classList.remove(this.openClass);
		this.el.setAttribute("aria-label", this.openLabel);
		this.el.setAttribute("aria-expanded", "true");

		this.labelEl.textContent = this.label;
	}

	open() {
		this.isOpen = true;

		this.el.classList.add(this.openClass);
		this.el.setAttribute("aria-label", this.closeLabel);
		this.el.setAttribute("aria-expanded", "false");

		this.labelEl.textContent = this.closeLabel;
	}
}
