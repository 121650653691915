import { Element } from "@/scripts/extends";

export default class Fps extends Element {
	mounted() {
		this.times = [];
		this.fps;

		this.refreshLoop();
	}

	refreshLoop() {
		window.requestAnimationFrame(() => {
			const now = performance.now();

			while (this.times.length > 0 && this.times[0] <= now - 1000) {
				this.times.shift();
			}
			this.times.push(now);

			this.fps = this.times.length;

			this.el.textContent = this.fps;

			this.refreshLoop();
		});
	}
}
