import { Element } from "@/scripts/extends";

export default class Figures extends Element {
	mounted() {
		this.titles = this.el.querySelectorAll(".c-figures__labels");

		if (this.titles < 2) {
			return;
		}

		this.rows = this.el.querySelectorAll(".c-figures__row");
		this.toggle = this.el.querySelector(".c-figures__toggle");

		this.toggleCorrectRow();
	}

	events() {
		this.toggle.addEventListener("change", () => this.toggleCorrectRow());
	}

	toggleCorrectRow() {
		if (this.toggle.checked) {
			this.rows[0].classList.add("hidden");
			this.rows[1].classList.remove("hidden");
			this.titles[0].classList.add("text-neutral-57");
			this.titles[1].classList.remove("text-neutral-57");

			return;
		}

		this.rows[1].classList.add("hidden");
		this.rows[0].classList.remove("hidden");
		this.titles[0].classList.remove("text-neutral-57");
		this.titles[1].classList.add("text-neutral-57");
	}
}
