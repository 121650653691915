export default class AccordionItem {
	constructor(el, index) {
		this.el = el;
		this.index = index;
		this.siblings = [];
		this.summary = el.querySelector(".c-accordion__summary");
		this.body = el.querySelector(".c-accordion__body");

		this.animation = null;
		this.isClosing = false;
		this.isExpanding = false;

		this.summary.addEventListener("click", (e) => this.onClick(e));
	}

	setSiblings(siblings) {
		this.siblings = siblings;
	}

	onClick(e) {
		e.preventDefault();
		// Check if the element is being closed or is already closed
		if (this.isClosing || !this.el.open) {
			this.open();
		// Check if the element is being opened or is already open
		} else if (this.isExpanding || this.el.open) {
			this.close();
		}
	}

	close() {
		this.isClosing = true;

		const startHeight = `${this.el.offsetHeight}px`;
		const endHeight = `${this.summary.offsetHeight}px`;

		// If there is already an animation running cancel it
		if (this.animation) {
			this.animation.cancel();
		}

		this.animation = this.el.animate({
			height: [startHeight, endHeight]
		}, {
			duration: 250,
			easing: "ease-out"
		});

		this.animation.onfinish = () => this.onAnimationFinish(false);
		this.animation.oncancel = () => this.isClosing = false;
	}

	open() {
		// Close all other accordions
		this.siblings.forEach((accordion) => {
			if (accordion.index === this.index) {
				return;
			}

			accordion.close();
		});

		this.el.style.height = `${this.el.offsetHeight}px`;
		// Force the [open] attribute on the details element
		this.el.open = true;
		// Wait for the next frame to call the expand function
		window.requestAnimationFrame(() => this.expand());
	}

	expand() {
		this.isExpanding = true;
		const startHeight = `${this.el.offsetHeight}px`;
		// Calculate the open height of the element (summary height + content height)
		const endHeight = `${this.summary.offsetHeight + this.body.offsetHeight}px`;

		// If there is already an animation running cancel it
		if (this.animation) {
			this.animation.cancel();
		}

		this.animation = this.el.animate({
			height: [startHeight, endHeight]
		}, {
			duration: 250,
			easing: "ease-out"
		});

		this.animation.onfinish = () => this.onAnimationFinish(true);
		this.animation.oncancel = () => this.isExpanding = false;
	}

	onAnimationFinish(open) {
		this.el.open = open;
		this.animation = null;
		this.isClosing = false;
		this.isExpanding = false;
		this.el.style.height = "";
	}
}
