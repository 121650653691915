import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SubNav from "./SubNav";

export default class Header extends Element {
	mounted() {
		this.isOpen = false;
		this.body = document.body;
		this.trigger = this.el.querySelector(".c-hamburger");
		this.closeSubNavTrigger = this.el.querySelector(".c-site-header__back-btn");
		this.subNavItems = this.el.querySelectorAll(".js-sub-nav");
		this.primaryButton = this.el.querySelector(".c-site-header__mobile-button");
		this.subNavItems = [...this.subNavItems].map((subNav) => new SubNav(subNav));

		this.initScrollAnimation();
	}

	events() {
		EventEmitter.on(EVENTS.SUB_NAV_TOGGLE, (isShown) => this.onOpenSubNav(isShown));

		if (!this.trigger) {
			return;
		}

		this.trigger.addEventListener("click", () => this.toggle());
		this.closeSubNavTrigger.addEventListener("click", () => this.closeSubNav());
	}

	initScrollAnimation() {
		ScrollTrigger.matchMedia({
			"(max-width: 767px)": () => {
				this.el.style.transform = "unset";
			},
			"(min-width: 768px)": () => {
				this.animation = gsap.from(this.el, {
					yPercent: -150,
					autoAlpha: 1,
					paused: true,
					duration: 0.33,
				}).progress(1);

				ScrollTrigger.create({
					start: "top top-=200px",
					end: 99999,
					onToggle: (self) => {
						if (self.isActive) {
							this.el.classList.add("has-shadow");

							return;
						}

						this.el.classList.remove("has-shadow");
					},
					onUpdate: (self) => {
						if (self.direction === -1) {
							this.animation.play();

							return;
						}

						this.animation.reverse();
					},
				});
			},
		});
	}

	onOpenSubNav(isShown) {
		if (isShown) {
			this.closeSubNavTrigger.classList.add("is-shown");
			this.primaryButton.classList.add("is-hidden");
		} else {
			this.closeSubNavTrigger.classList.remove("is-shown");
			this.primaryButton.classList.remove("is-hidden");
		}
	}

	toggle() {
		if (this.isOpen) {
			this.disable();
		} else {
			this.enable();
		}
	}

	enable() {
		this.el.classList.add("is-open");
		this.body.classList.add("has-scroll-disabled", "has-scroll-disabled--mobile");
		this.isOpen = true;
	}

	disable() {
		this.el.classList.remove("is-open");
		this.body.classList.remove("has-scroll-disabled", "has-scroll-disabled--mobile");
		this.closeSubNavTrigger.classList.remove("is-shown");
		this.isOpen = false;

		this.subNavItems.forEach(item => {
			item.disable();
		});
	}

	closeSubNav() {
		this.subNavItems.forEach(item => {
			item.disable();
		});

		this.closeSubNavTrigger.classList.remove("is-shown");
		EventEmitter.emit(EVENTS.SUB_NAV_TOGGLE, false);
	}
}
