/**
 * In the /scripts/singletons/DomManager file, when using the setComponents
 * method, all components placed in export default are looped and initialized
 * automatically.
 */
import Button from "@/scripts/components/Button";
import Flyout from "@/scripts/components/Flyout";
import Hamburger from "@/scripts/components/Hamburger";
import Slider from "@/scripts/components/Slider";
import Overlay from "@/scripts/components/Overlay";
import Video from "@/scripts/components/Video";
import Figures from "@/scripts/components/Figures";
import Logos from "@/scripts/components/Logos";
import Accordion from "@/scripts/components/Accordion";
import AnimatedSplits from "@/scripts/components/AnimatedSplits";
import Steps from "@/scripts/components/Steps";
import Header from "@/scripts/components/Header";

/**
 * If you want to pass separate parameters or want to initialize a component
 * manually, you have to:
 * - Export it by itself =>
 *   export { default as Button } from "@/scripts/components/Button";
 * - Import it in the main.js file =>
 *   import { Button } from "@/scripts/components";
 * - Use the DomManager to register it:
 *   DomManager.registerComponent(".js-button", Button);
 */
// export { default as Button } from "@/scripts/components/Button";

export default {
	Button,
	Flyout,
	Hamburger,
	Slider,
	Overlay,
	Video,
	Figures,
	Logos,
	Accordion,
	AnimatedSplits,
	Steps,
	Header
};
