import { Element } from "@/scripts/extends";
import AccordionItem from "./AccordionItem";

export default class Faq extends Element {
	mounted() {
		this.items = this.el.querySelectorAll(".c-accordion__details");

		this.accordions = [...this.items].map((el, index) => new AccordionItem(el, index));

		this.accordions.forEach((accordion) => {
			accordion.setSiblings(this.accordions);
		});
	}
}
