import Swiper, { Autoplay, Pagination } from "swiper";

import { Element } from "@/scripts/extends";

export default class Slider extends Element {
	mounted() {
		if (this.el.classList.contains("swiper")) {
			Swiper.use([Autoplay, Pagination]);

			this.setSwiper();
		}
	}

	setSwiper() {
		const customConfig = JSON.parse(this.el.dataset.swiperConfig);

		const swiperConfig = {
			direction: 'horizontal',
			slidesPerView: 1,
			spaceBetween: 30,
			wrapperClass: "c-slider__wrapper",
			slideActiveClass: "is-active",
			slideClass: "c-slide",
			navigation: {
				nextEl: ".c-slider__button--next",
				prevEl: ".c-slider__button--prev",
				disabledClass: "is-disabled",
			},
		};

		const mergedConfig = {
			...swiperConfig,
			...customConfig
		};

		this.swiper = new Swiper(this.el, mergedConfig);

		this.swiper.init();

		if (!mergedConfig.pagination) {
			return;
		}

		// extend custom pagination because swiper doesn't add active class
		this.swiper.pagination.bullets = this.el.getElementsByClassName(mergedConfig.pagination.bulletClass);

		this.swiper.on("activeIndexChange", (e) => {
			[...this.swiper.pagination.bullets].forEach((bullet, index) => {
				if (index !== e.activeIndex) {
					bullet.classList.remove(mergedConfig.pagination.bulletActiveClass);

					return;
				}

				bullet.classList.add(mergedConfig.pagination.bulletActiveClass);
			});
		})
	}
}
