import { Element } from "@/scripts/extends";
import Native from "./Native";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class Video extends Element {
	mounted() {
		this.activeClass = "is-active";

		this.name = this.el.getAttribute("data-name")
		this.trigger = this.el.querySelector(".js-video__trigger");
		this.videoNativeEl = this.el.querySelector(".c-video--native");

		/* Embedded videos are appended in an overlay at the end of the body */
		this.videoEmbedEl = document.querySelector(`.c-video--embed[data-name="${this.name}"]`);
		if (this.videoEmbedEl) {
			this.frame = this.videoEmbedEl.querySelector(".c-video__frame");
			this.src = this.frame.getAttribute("src");
		}
	}

	events() {
		EventEmitter.on(`${EVENTS.OVERLAY_TRIGGER_CLOSE_OVERLAY}-${this.name}`, () => this.handlePause());

		if (this.trigger) {
			this.trigger.addEventListener("click", () => this.handleClick());
		}

		if (this.videoEmbedEl && this.frame) {
			this.frame.addEventListener("load", () => {
				this.listenToEmbed();
				this.initEmbedReady();
			})
		}

		if (this.videoNativeEl) {
			this.videoNative = new Native(this.videoNativeEl, {
				pauseCb: () => this.handlePause(),
				endedCb: () => this.handleEnded(),
			})
		}
	}

	handleClick() {
		this.el.classList.add(this.activeClass);
		EventEmitter.emit(`${EVENTS.OVERLAY_TRIGGER_SHOW_OVERLAY}-${this.name}`);

		if (this.videoNativeEl) {
			this.videoNative.play();
		}

		if (this.videoEmbedEl) {
			this.playEmbed();
		}
	}

	handlePause() {
		this.handlePauseVideo();
	}

	handlePauseVideo() {
		this.el.classList.remove(this.activeClass);

		if (this.videoEmbedEl) {
			this.stopEmbed();
		}
	}

	handleEnded() {
		this.el.classList.remove(this.activeClass);
	}

	handleStop() {
		if (this.videoNativeEl) {
			this.videoNative.stop();
		}
	}

	postMessageToPlayer(command) {
		if (command == null) return;

		this.frame.contentWindow.postMessage(JSON.stringify(command), "*");
	}

	initEmbedReady() {
		this.el.classList.remove("is-loading");

		if (this.el.classList.contains("js-autoplay")) {
			this.autoplayEmbed();
		}
	}

	listenToEmbed() {
		this.postMessageToPlayer({
			"event": "listening",
			"id": "1",
			"channel": "widget"
		});
	}

	autoplayEmbed() {
		this.postMessageToPlayer({
			"event": "command",
			"func": "playVideo",
		});
	}

	playEmbed() {
		this.src = this.frame.getAttribute("src");
		if(!this.src.includes("autoplay")) {
			this.frame.setAttribute("src", `${this.src}&autoplay=1`);
		}
	}

	stopEmbed() {
		this.src = this.frame.getAttribute("src").replace(/&autoplay=1$/, "");
		this.frame.setAttribute("src", this.src);
	}

	pauseEmbed() {
		this.postMessageToPlayer({
			"event": "command",
			"func": "pauseVideo",
		});
	}
}
