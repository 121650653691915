import { EventEmitter } from "@/scripts/core";
import { EVENTS, BREAKPOINTS } from "@/scripts/constants";
import keyboard from "../singletons/Keyboard";

export default class SubNav {
	constructor(el) {
		this.el = el
		this.isOpen = false;
		this.triggerHsMouseEntered = false;
		this.wrapperHasMouseEntered = false;
		this.isMobile = false;
		this.trigger = this.el.querySelector(".c-sub-nav__trigger");
		this.wrapper = this.el.querySelector(".c-sub-nav__wrapper");

		this.events();
		this.initKeyboard();
	}

	events() {
		EventEmitter.on(EVENTS.BREAKPOINT_CHANGE, (breakpoint) => this.onScreenSizeChange(breakpoint));

		this.trigger.addEventListener("click", () => this.onClickTrigger());
	}

	onScreenSizeChange(breakpoint) {
		const storeIsMobile = this.isMobile;

		this.isMobile = breakpoint < BREAKPOINTS.VP_12;

		if (storeIsMobile === this.isMobile) {
			return;
		}

		this.disable();
		EventEmitter.emit(EVENTS.SUB_NAV_TOGGLE, false);
	}

	initKeyboard() {
		keyboard.listenOnKeyDown((e) => this.onKeydown(e));
	}

	onKeydown(e) {
		this.handleEsc(e);
	}

	handleEsc(e) {
		const isEsc = 27;

		if (e.keyCode === isEsc && this.el.matches(':focus-within')) {
			this.trigger.focus();
		}
	}

	onClickTrigger() {
		if (this.isMobile) {
			console.log("click");
			this.toggle();
		}
	}

	toggle() {
		if (this.isOpen) {
			this.disable();
		} else {
			this.enable();
		}

		EventEmitter.emit(EVENTS.SUB_NAV_TOGGLE, this.isOpen);
	}

	enable() {
		if (this.el.classList.contains("is-open")) {
			return;
		}

		this.el.classList.add("is-open");
		this.isOpen = true;
	}

	disable() {
		if (!this.el.classList.contains("is-open")) {
			return;
		}

		this.el.classList.remove("is-open");
		this.isOpen = false;
	}
}
