import { Element } from "@/scripts/extends";
import gsap from "gsap";

export default class Parallax extends Element {
	mounted() {
		const $targets = this.el.querySelectorAll('.js-parallax__item');

		if (!$targets || $targets.length <= 0) {
			return;
		}

		$targets.forEach((target, index) => {
			const distance = 100 + (180 * index);

			const [y, yEnd] =  [`${distance}px`, `-${distance}px`];

			gsap.fromTo(target, {  y  }, {
				y: yEnd,
				scrollTrigger: {
					trigger: this.el,
					start: "top bottom",
					end: "bottom top",
					scrub: 1
				}
			});
		})
	}
}
