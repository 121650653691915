export default class Singleton {
	set(...params) {
		if (this.init) {
			this.init(...params);
		}

		if (this.events) {
			this.events(...params);
		}
	}
}
