import { Element } from "@/scripts/extends";
import Split from "./Split";
import { EventEmitter } from "@/scripts/core";
import { EVENTS, BREAKPOINTS } from "@/scripts/constants";

export default class AnimatedSplits extends Element {
	mounted() {
		this.hiddenClass = "hidden";
		this.isMobile = null;
		this.containerElement = this.el.parentNode.parentNode;

		this.desktopAssetsContainer = this.el.querySelector(".c-animated-splits__desktop-assets");
		this.desktopAssetsColumn = this.desktopAssetsContainer.querySelector(".c-animated-splits__desktop-assets-column");
		this.desktopAssets = [];

		this.items = this.el.querySelectorAll(".c-animated-splits__item");
		this.splits = [...this.items].map((el, index) => new Split(el, index, this.hiddenClass));

		this.updateDom(window.innerWidth);
	}

	events() {
		EventEmitter.on(EVENTS.SCREEN_SIZE_CHANGE, (breakpoint) => this.updateDom(breakpoint));

		// The container element that observes interactive elements
		this.intersectionObserver = new IntersectionObserver(
			(items) => {
				this.intersect(items);
			},
			{
				rootMargin: "-50% 0% -50% 0%",
				threshold: [0, 0],
			}
		);

		// Interactive elements
		this.items.forEach((item) => {
			this.intersectionObserver.observe(item);
		});
	}

	intersect(entries) {
		let activeIndex = null;
		const activeClass = "is-visible";

		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				activeIndex = entry.target.dataset.index;
			}
		});

		this.desktopAssets.forEach((asset, index) => {
			if (asset.classList.contains(activeClass)) {
				asset.classList.remove(activeClass);
			}

			if (index <= activeIndex) {
				asset.classList.add(activeClass);
			}
		})
	}

	updateDom(breakpoint) {
		const isMobile = breakpoint < BREAKPOINTS.VP_7;

		if (this.isMobile === isMobile) {
			return;
		}

		if (isMobile) {
			this.initMobile();

			this.isMobile = isMobile;

			return;
		}

		this.initDesktop();

		this.isMobile = isMobile;
	}

	initMobile() {
		this.splits.forEach(split => split.showAsset());
		this.toggleDesktopAssets(false);
	}

	initDesktop() {
		this.splits.forEach(split => split.hideAsset());

		if (this.desktopAssets.length < 1) {
			this.splits.forEach((split, index) => {
				const asset = split.getAsset();
				const clone = asset.cloneNode(true);

				clone.classList.add("c-animated-splits__desktop-asset");
				if (!index) {
					clone.classList.add("is-visible");
				}

				this.desktopAssetsColumn.appendChild(clone);

				this.desktopAssets.push(clone);
			});
		}

		this.toggleDesktopAssets(true);
	}

	toggleDesktopAssets(isDesktop) {
		const desktopAssetsAreHidden = this.desktopAssetsContainer.classList.contains(this.hiddenClass);

		if (isDesktop) {
			if (desktopAssetsAreHidden) {
				this.desktopAssetsContainer.classList.remove(this.hiddenClass);
			}

			return;
		}

		if (!desktopAssetsAreHidden) {
			this.desktopAssetsContainer.classList.add(this.hiddenClass);
		}
	}
}
