export default class Split {
	constructor(el, index, hiddenClass) {
		this.el = el;
		this.index = index;
		this.hiddenClass = hiddenClass;

		this.asset = this.el.querySelector(".c-animated-splits__item-media");
	}

	hideAsset() {
		if (!this.asset.classList.contains(this.hiddenClass)) {
			this.asset.classList.add(this.hiddenClass);
		}
	}

	showAsset() {
		if (this.asset.classList.contains(this.hiddenClass)) {
			this.asset.classList.remove(this.hiddenClass);
		}
	}


	getAsset() {
		return this.asset.querySelector(".c-animated-splits__item-asset");
	}
}
