import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS, BREAKPOINTS } from "@/scripts/constants";

export default class Logos extends Element {
	mounted() {
		this.speed = 1500;
		this.frequency = 4000;
		this.logoSize = 110;
		this.interval = null;

		this.cols = this.el.querySelectorAll(".c-logos_column");
		this.logos = [...this.cols].map((col) => col.querySelectorAll(".c-logo"));
		this.indexes = this.initIndexes();

		if (window.innerWidth > BREAKPOINTS.VP_7) {
			this.initAnimation();

			return;
		}

		this.renderMobileLogos();
	};

	events() {
		EventEmitter.on(EVENTS.BREAKPOINT_CHANGE, (breakpoint) => this.setResponsiveMode(breakpoint));
	}

	setResponsiveMode(breakpoint) {
		if (breakpoint < BREAKPOINTS.VP_7) {
			/**
			 * Don't unnecessarily update the dom when there is no animation
			 * (for instance when the user initially views in mobile mode)
			 */
			if (!this.interval) {
				return;
			}

			clearInterval(this.interval);
			this.interval = null;

			this.destroyAnimation();
			this.renderMobileLogos();

			return;
		}

		if (this.interval) {
			return;
		}

		this.initAnimation();
	}

	initIndexes() {
		return [...this.cols].map(() => 0);
	}

	initAnimation() {
		this.prepareAnimation();
		this.playAnimation();
	}

	setRandomColIndex(index) {
		this.indexes[index] = this.generateRandomIndex(index);
		this.transformColumn(index);
	}

	prepareAnimation() {
		this.el.classList.remove("c-logos--mobile");

		this.cols.forEach((col, index) => {
			col.style.transition = `transform ${this.speed}ms cubic-bezier(0,.24,.19,1)`;
			this.setRandomColIndex(index);
		});
	};

	playAnimation() {
		this.interval = setInterval(() => {
			this.cols.forEach((_, index) => {
				this.setRandomColIndex(index);
			});
		}, this.frequency);
	}

	generateRandomIndex(index) {
		const logoAmount = this.logos[index].length;

		// Prevent an infinite regression loop when only 1 logo is uploaded
		if (logoAmount <= 1) {
			return 0;
		}

		const randomIndex = Math.floor(Math.random() * logoAmount);

		return this.indexes[index] === randomIndex ? this.generateRandomIndex(index) : randomIndex;
	};

	renderMobileLogos() {
		console.log("mobile");
		this.el.classList.add("c-logos--mobile");
	}

	transformColumn(index) {
		this.cols[index].style.transform = `translateY(${this.indexes[index] * -this.logoSize}px)`;
	};

	destroyAnimation() {
		this.cols.forEach((col) => {
			this.indexes = this.initIndexes();
			col.style.transition = "unset";
			col.style.transform = "unset";
		});
	}
}
