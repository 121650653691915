import { Singleton } from "@/scripts/extends";
import { EVENTS } from "@/scripts/constants";
import { EventEmitter } from "@/scripts/core";

class Mouse extends Singleton {
	events() {
		window.addEventListener("mousemove", this.onMouseMove);
		window.addEventListener("click", this.onMouseClick);
	}

	onMouseClick(e) {
		EventEmitter.emit(EVENTS.MOUSE_CLICK, e);
	}

	onMouseMove(e) {
		EventEmitter.emit(EVENTS.MOUSE_MOUSE_MOVE, e);
	}

	listenOnMouseMove(callback) {
		EventEmitter.on(EVENTS.MOUSE_MOUSE_MOVE, callback);
	}

	listenOnMouseClick(callback) {
		EventEmitter.on(EVENTS.MOUSE_CLICK, callback);
	}
}

const mouse = new Mouse();

export default mouse;
