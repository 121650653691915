export default class StepMobile {
	constructor(el, index, activeIndex, showCycle) {
		this.el = el;
		this.index = index;
		this.activeIndex = activeIndex;
		this.isActive = false;
		this.showCycle = showCycle;
		this.header = el.querySelector(".c-steps__step-header");
		this.content = el.querySelector(".c-steps__step-content");

		this.closedHeight = 0;
		this.openedHeight = 0;
	}

	initHeights() {
		this.toggleShowCycle();

		this.isActive = true;

		this.el.classList.add("is-active");
		this.content.style.height = "auto";

		const { offsetHeight, offsetWidth } = this.content;

		this.closedHeight = `${this.header.offsetHeight}px`;
		this.openedHeight = `${offsetHeight}px`;

		this.initLineSize(offsetWidth, offsetHeight);

		if (this.index === this.activeIndex) {
			return;
		}

		this.el.classList.remove("is-active");
		this.content.style.height = this.closedHeight;

		this.isActive = false;
	}

	initLineSize(width, height) {
		/**
		 *  Calculate the size based on the contents dimensions to make sure it
		 *  overlaps perfectly with content when rotating
		*/
		const c = Math.sqrt(Math.pow(height, 2) + Math.pow(width, 2));

		// Choose the width based on the largest axis
		if (height > width) {
			// x-axis needs to move
			const x = -((width * 0.5) + ((c * 0.5) - width));
			// Calculate how much the y-axis needs to move
			const y = (c - height) * -0.5;

			this.el.style.setProperty('--c-step-line-width', `${c}px`);
			this.el.style.setProperty('--c-step-line-translate', `translate(${x}px, ${y}px)`);

			return;
		}

		// Calculate how much the x-axis needs to move
		const x = (c - width) * -0.5;
		// Calculate how much the y-axis needs to move
		const y = -((height * 0.5) + ((c * 0.5) - height));

		this.el.style.setProperty('--c-step-line-width', `${c}px`);
		this.el.style.setProperty('--c-step-line-translate', `translate(${x}px, ${y}px)`);
	}

	setActiveIndex(index) {
		this.activeIndex = index;
	}

	setShowCycle(showCycle) {
		this.showCycle = showCycle;

		this.toggleShowCycle();
	}

	toggleShowCycle() {
		if (this.showCycle) {
			this.el.classList.add("show-cycle");

			return;
		}

		this.el.classList.remove("show-cycle");
	}

	open() {
		this.isActive = true;
		this.el.classList.add("is-active");

		this.animate(this.closedHeight, this.openedHeight, () => null);
	}

	close() {
		this.el.classList.add("will-hide");

		this.animate(this.openedHeight, this.closedHeight, () => {
			this.el.classList.remove("is-active");
			this.el.classList.remove("will-hide");
			this.isActive = false;
		});
	}

	animate(start, end, onFinishCallback) {
		this.content.style.height = "";

		this.animation = this.content.animate({
			height: [start, end]
		}, {
			duration: 250,
			easing: "ease-out"
		});

		this.animation.onfinish = () => {
			this.content.style.height = "";

			onFinishCallback();
		};
	}

	destroy() {
		this.content.style.height = "";
	}
}
