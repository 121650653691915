import { Element } from "@/scripts/extends";
import { EVENTS, BREAKPOINTS } from "@/scripts/constants";
import { EventEmitter } from "@/scripts/core";
import gsap from "gsap";

class Body extends Element {
	mounted() {
		this.activeBreakpoint = undefined;
	}

	events() {
		this.resizeObserver = new ResizeObserver((entries) => {
			const body = entries[0];

			const storedActiveBreakpoint = this.activeBreakpoint;

			this.activeBreakpoint = this.calculateActiveBreakpoint(body.contentRect.width)

			EventEmitter.emit(EVENTS.SCREEN_SIZE_CHANGE, this.activeBreakpoint);

			if (storedActiveBreakpoint === this.activeBreakpoint) {
				return;
			}

			EventEmitter.emit(EVENTS.BREAKPOINT_CHANGE, this.activeBreakpoint);
		});

		this.resizeObserver.observe(this.el);

		this.initAnchorLinks();
	}

	calculateActiveBreakpoint(width) {
		if (width <= BREAKPOINTS.VP_5) {
			return BREAKPOINTS.VP_3;
		} else if (width > BREAKPOINTS.VP_5 && width <= BREAKPOINTS.VP_7) {
			return BREAKPOINTS.VP_5;
		} else if (width > BREAKPOINTS.VP_7 && width <= BREAKPOINTS.VP_9) {
			return BREAKPOINTS.VP_7;
		} else if (width > BREAKPOINTS.VP_9 && width <= BREAKPOINTS.VP_12) {
			return BREAKPOINTS.VP_9;
		} else if (width > BREAKPOINTS.VP_12 && width <= BREAKPOINTS.VP_14) {
			return BREAKPOINTS.VP_12;
		} else if (width > BREAKPOINTS.VP_14 && width <= BREAKPOINTS.VP_15) {
			return BREAKPOINTS.VP_14;
		} else if (width < BREAKPOINTS.VP_15) {
			return BREAKPOINTS.VP_15;
		}
	}

	initAnchorLinks() {
		// Scroll to the element in the URL's hash on load
		scrollToHash(window.location.hash, null, 0);

		this.el.querySelectorAll('a[href]').forEach(a => {
			a.addEventListener('click', e => {
				scrollToHash(getSamePageAnchor(a), e);
			});
		});

		// Detect if a link's href goes to the current page
		function getSamePageAnchor (link) {
			if (
				link.protocol !== window.location.protocol ||
				link.host !== window.location.host ||
				link.pathname !== window.location.pathname ||
				link.search !== window.location.search
			) {
				return false;
			}

			return link.hash;
		}

		// Scroll to a given hash, preventing the event given if there is one
		function scrollToHash(hash, e, duration = 0.5) {
			const elem = hash ? document.querySelector(hash) : false;
			if(elem) {
				if(e) e.preventDefault();
				let options = {
					scrollTo: { y: elem, offsetY: 100 },
					duration: duration,
				};

				// Mobile
				if (window.innerWidth < 768) {
					options.scrollTo.offsetY = 75;
					options.duration = 0;
				}

				gsap.to(window, options);
			}
		}
	}
}

export default Body;
